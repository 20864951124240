import React, { useEffect } from 'react';
import { Box } from '@coolblue-development/becky';
import CountrySelector from './CountrySelector';
import { IconButton, OutlinedInput } from '@mui/material';
import ContactsIcon from '../../../Icons/ContactsIcon';
import { useAppContext } from '../../../../../AppContext';
import { TelephonyWidgetContainer } from '../../../../../models/TelephonyWidgetContainer';
import parsePhoneNumber from 'libphonenumber-js';
import { Country } from '../../../../../models/Country';

interface ContactPhoneInputProps {
  inputValue: string;
  onInputChange: (value: string) => void;
  onCountrySelect: (country: Country) => void;
  selectedCountry: Country | null;
  onFormattedPhoneNumberChange: (formattedNumber: string) => void;
}

const ContactPhoneInput: React.FC<ContactPhoneInputProps> = ({
  inputValue,
  onInputChange,
  onCountrySelect,
  selectedCountry,
  onFormattedPhoneNumberChange
}) => {
  const { setActiveTelephonyWidgetContainer } = useAppContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onInputChange(event.target.value);
  };

  useEffect(() => {
    const phoneNumber = parsePhoneNumber(inputValue, selectedCountry?.country);

    if (phoneNumber?.isValid()) {
      const formatted = phoneNumber.formatInternational();
      onFormattedPhoneNumberChange(formatted);
    } else {
      onFormattedPhoneNumberChange('');
    }
  }, [inputValue, onInputChange, onFormattedPhoneNumberChange, selectedCountry]);

  return (
    <Box sx={{ display: 'flex', marginY: '24px', gap: '4px' }}>
      <IconButton
        onClick={() => setActiveTelephonyWidgetContainer(TelephonyWidgetContainer.QUICK_CONNECT)}
        data-testid="quickConnects-button"
        size="small"
        sx={{
          border: '1px solid #262930',
          borderRadius: '4px',
          padding: '16px',
          ':hover': { background: '#33373F', boxShadow: 'none' }
        }}
      >
        <ContactsIcon />
      </IconButton>
      <Box
        sx={{
          position: 'relative',
          '& .MuiOutlinedInput-root': {
            border: '1px solid #262930',
            '&.Mui-focused fieldset': {
              border: 'none'
            }
          }
        }}
      >
        <OutlinedInput
          data-testid="phone-input"
          sx={{ paddingLeft: '65px' }}
          value={inputValue}
          onChange={handleChange}
          inputProps={{ style: { color: 'white' } }}
          placeholder="123 45 67 89"
        />
        <Box sx={{ display: 'flex', height: '100%', position: 'absolute', top: 0, left: '5px' }}>
          <CountrySelector onCountrySelect={onCountrySelect} />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactPhoneInput;

import { Box, Grid, IconButton, Typography } from '@coolblue-development/becky';
import React, { useEffect, useState } from 'react';
import InboundIcon from '../../../Icons/InboundIcon';
import TelephoneIcon from '../../../Icons/TelephoneIcon';
import { datadogLogs } from '@datadog/browser-logs';

interface RecentCall {
  phoneNumber: string;
  isInbound: boolean;
  currentTimeStamp: string;
}

const RecentCallContainer = (): JSX.Element => {
  const recentCalls = JSON.parse(localStorage.getItem('recentCalls') || '[]') as RecentCall[];
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  useEffect(() => {
    datadogLogs.logger.info(`Recent calls opened`, { recentCalls: true });
  }, []);

  const callOutboundContact = (phoneNumber: string): void => {
    connect.agent(agent => {
      const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber);
      agent.connect(endpoint);
    });
  };

  return (
    <Box
      sx={{
        color: 'white',
        height: '100%',
        scrollbarWidth: 'none',
        overflow: 'auto',
        marginBottom: '-24px'
      }}
    >
      <Typography color={'#fff'} variant="h4">
        Recent
      </Typography>
      <Box sx={{ marginTop: '24px' }}>
        {recentCalls.map((call, index) => (
          <Grid
            key={index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            container
            sx={{
              alignItems: 'center',
              borderTop: '1px solid #262930',
              textAlign: 'left',
              padding: '10px 15px',
              borderRadius: '4px',
              ':hover': { background: '#262930' },
              transition: 'background 0.3s ease'
            }}
          >
            <Grid item xs={1}>
              <InboundIcon color={call.isInbound ? '#00B900' : '#FF6600'} rotation={call.isInbound ? 0 : 180} />
            </Grid>
            <Grid item xs={8}>
              <Typography>{call.phoneNumber}</Typography>
              <Typography>{call.isInbound ? 'Inbound' : 'Outbound'}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Typography
                sx={{
                  marginRight: hoveredIndex === index ? '10px' : '0px',
                  transition: 'margin-right 0.3s ease'
                }}
              >
                {call.currentTimeStamp}
              </Typography>
              <IconButton
                data-testid="recent-contact"
                onClick={() => {
                  callOutboundContact(call.phoneNumber);
                }}
                sx={{
                  visibility: hoveredIndex === index ? 'visible' : 'hidden',
                  marginRight: hoveredIndex === index ? '0px' : '-35px',
                  transition: 'margin-right 0.3s ease',
                  background: 'none',
                  width: '35px',
                  height: '35px',
                  borderRadius: '50px',
                  boxShadow: 'none',
                  ':hover': { svg: { fill: '#00B900' }, background: '#33373F', boxShadow: 'none' }
                }}
              >
                <TelephoneIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default RecentCallContainer;

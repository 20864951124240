import React from 'react';
import { Box } from '@coolblue-development/becky';
import SideMenu from './SideMenu';

const SidePanel = (): JSX.Element => {
  return (
    <Box
      sx={{
        minWidth: '167px',
        paddingTop: '25px',
        borderRight: '#DDDDDD 1px solid',
        position: 'relative',
        height: '100vh'
      }}
    >
      <SideMenu />
    </Box>
  );
};

export default SidePanel;

import { Box, Label, Typography } from '@coolblue-development/becky';
import React, { useEffect, useState } from 'react';
import InboundIcon from '../../../Icons/InboundIcon';
import { useAppContext } from '../../../../../AppContext';

interface RecentCall {
  phoneNumber: string;
  isInbound: boolean;
  currentTimeStamp: string;
}

interface SelectRecentCallProps {
  handleSelectedRecentCall: (phoneNumber: string, index: number) => void;
}

const TelephonySidePanel: React.FC<SelectRecentCallProps> = ({ handleSelectedRecentCall }) => {
  const { voiceContact } = useAppContext();
  const [recentCalls, setRecentCalls] = useState<RecentCall[]>([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);

  useEffect(() => {
    const storedCalls = JSON.parse(localStorage.getItem('recentCalls') || '[]') as RecentCall[];
    setRecentCalls(storedCalls);
  }, [voiceContact]);

  const handleItemClick = (index: number, phoneNumber: string): void => {
    setSelectedItemIndex(index);
    handleSelectedRecentCall(phoneNumber, index);
  };

  useEffect(() => {
    if (voiceContact) {
      setSelectedItemIndex(0);
      handleSelectedRecentCall(recentCalls[0]?.phoneNumber, 0);
    }
  }, [voiceContact, recentCalls, handleSelectedRecentCall]);

  return (
    <Box sx={{ minWidth: '275px', padding: '8px', borderRight: '#DDDDDD 1px solid', overflow: 'auto' }}>
      {recentCalls.map((call, index) => (
        <Box
          data-testid={`recent-call-${index}`}
          onClick={() => handleItemClick(index, call.phoneNumber)}
          key={index}
          sx={{
            padding: '15px',
            borderRadius: '5px',
            marginBottom: '8px',
            border: `1px solid ${selectedItemIndex === index ? '#0090E3' : '#DDDDDD'}`,
            ':hover': { cursor: 'pointer' }
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <InboundIcon color={call.isInbound ? '#00B900' : '#FF6600'} rotation={call.isInbound ? 0 : 180} />
              <Typography variant="h5">{call.phoneNumber}</Typography>
            </Box>
            {index === 0 && voiceContact ? (
              <Label color="blue">Active call</Label>
            ) : (
              <Typography variant="body1"> {call.currentTimeStamp} </Typography>
            )}
          </Box>
          <Typography variant="body1"> {call.isInbound ? 'Inbound call' : 'Outbound call'} </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default TelephonySidePanel;

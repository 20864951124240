import { useEffect, useLayoutEffect, useState } from 'react';

const VISIBILITY_EVENT_TYPE = 'visibilitychange';

const usePageVisible = (): { isPageVisible: boolean } => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const handleOnVisibilityChange = (): void => setIsVisible(!document.hidden);

  useEffect(() => {
    setIsVisible(isVisible);
  }, [isVisible]);

  useLayoutEffect(() => {
    document.addEventListener(VISIBILITY_EVENT_TYPE, handleOnVisibilityChange);

    return () => {
      document.removeEventListener(VISIBILITY_EVENT_TYPE, handleOnVisibilityChange);
    };
  }, []);

  return { isPageVisible: isVisible };
};

export default usePageVisible;

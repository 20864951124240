export const processAttachment = async (
  attachment: connect.AttachmentItem[] | undefined,
  chatSession: connect.AgentChatSession
): Promise<Blob | undefined> => {
  if (!attachment) {
    return undefined;
  }

  const processedAttachment = await chatSession.downloadAttachment({
    attachmentId: attachment[0].AttachmentId
  });

  return processedAttachment;
};

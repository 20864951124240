import { BeckyThemeProvider, beckyTheme } from '@coolblue-development/themes';
import { useCallback, useEffect, useRef, useState } from 'react';

import CassieInjector from './components/CassieContainer/CassieInjector';
import CatoContainer from './components/CatoContainer/CatoContainer';
import Contact from './models/Contact';
import { CssBaseline } from '@mui/material';
import ToolkitPanel from './components/ToolkitPanel/ToolkitPanel';
import { datadogLogs } from '@datadog/browser-logs';
import ErrorBoundary from './ErrorBoundary';

interface CatoConfProps {
  ccpInstanceURL: string;
  cppSamURL: string;
  title: string;
  region: string;
}

function App({ ccpInstanceURL, cppSamURL, title, region }: CatoConfProps): JSX.Element {
  const [contact, setContact] = useState<Contact | undefined>(undefined);
  const prevContactRef = useRef<Contact | undefined>(undefined);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      service: 'browser/cato',
      sessionSampleRate: 100,
      env: process.env.REACT_APP_DATADOG_ENV as string // development, acceptance, testing, production
    });
  }, []);

  const onViewContact = useCallback((viewContact: Contact) => {
    if (prevContactRef.current?.contactId === viewContact.contactId) return;

    const newContact = viewContact.contactId === '' ? undefined : viewContact;
    setContact(newContact);
    prevContactRef.current = newContact;
  }, []);

  return (
    <>
      <BeckyThemeProvider theme={beckyTheme}>
        <ErrorBoundary>
          <CssBaseline />
          <CatoContainer
            region={region}
            instanceURL={ccpInstanceURL}
            samURL={cppSamURL}
            onViewContact={onViewContact}
          />
          <CassieInjector contact={contact} />
          <ToolkitPanel />
        </ErrorBoundary>
      </BeckyThemeProvider>
    </>
  );
}

export default App;

import { ReactComponent as Telephone } from '../../../assets/telephone.svg';

const TelephoneIcon = (
  props: React.SVGProps<SVGSVGElement> & { disabled?: boolean; rotation?: number }
): JSX.Element => {
  const { rotation = 0, disabled, ...restProps } = props;

  return <Telephone fill={disabled ? 'black' : 'white'} transform={`rotate(${rotation})`} {...restProps} />;
};

export default TelephoneIcon;

const getEnvironmentVariable = (environmentVariable: string): string => {
  const invalidatedEnvironmentVariable = process.env[environmentVariable];
  if (!invalidatedEnvironmentVariable) {
    throw new Error(`Couldn't find environment variable: ${environmentVariable}`);
  } else {
    return invalidatedEnvironmentVariable;
  }
};

const config = {
  ccpInstanceURL: getEnvironmentVariable('REACT_APP_CCP_INSTANCE_URL'),
  cppSamURL: getEnvironmentVariable('REACT_APP_SAML_URL'),
  cassieWebsocketURL: getEnvironmentVariable('REACT_APP_CASSIE_WS_URL'),
  cassieWebsocketProtocol: getEnvironmentVariable('REACT_APP_CASSIE_PROTOCOL'),
  title: getEnvironmentVariable('REACT_APP_TITLE'),
  region: getEnvironmentVariable('REACT_APP_REGION')
};

export default config;

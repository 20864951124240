import React from 'react';
import { Button } from '@mui/material';
import { useAppContext } from '../../../../../AppContext';
import { TelephonyWidgetContainer } from '../../../../../models/TelephonyWidgetContainer';
import ChevronIcon from '../../../Icons/ChevronIcon';

const BackButton = (): JSX.Element => {
  const { setActiveTelephonyWidgetContainer, voiceContact } = useAppContext();

  const handleBackClick = (): void => {
    setActiveTelephonyWidgetContainer(voiceContact ? TelephonyWidgetContainer.CALL : TelephonyWidgetContainer.OUTBOUND);
  };

  return (
    <>
      <Button
        sx={{ color: '#fff', fontWeight: '100', ':hover': { background: 'none' } }}
        data-testid="back-button"
        startIcon={<ChevronIcon rotation={0} />}
        onClick={handleBackClick}
      >
        Back
      </Button>
    </>
  );
};

export default BackButton;

export const LoadingQuotes = [
  'Connecting you to a (friendly) customer',
  'o(>ω<)o',
  'Meow 🐶',
  'Sewyyyyyy',
  'Stay hydrated 🫵',
  'SPOW POW',
  "No, we don't sell Fortnite accounts sir",
  "Hokeee, let's go",
  'Real genius quotes here, no AI 🏃',
  "Don't leave customers on read like you do your friends",
  'I <3 Cato t-shirts coming soon',
  'Loaaaadddding...',
  'On it',
  "I ain't felt like this in a long time",
  "Don't search for a vibrator in the webshop",
  'Try adding 69 items in your shopping cart in the webshop',
  'Cato (pronounce as: keɪtoʊ, KAY-toh), not Kato 😠',
  'What if..',
  '🐈🅾️',
  'Purrrrr',
  '"Marlin. & Shqq - Gewoon, doen" > Every 101 Barz sessie',
  '🆗',
  'Why did you redeem it?',
  'Give me my money.... 👏',
  'Two steps ahead',
  '1 sec'
];

import { Box } from '@coolblue-development/becky';
import { useEffect, useRef } from 'react';
import Incoming from './ContactTab/Incoming';
import Ended from './ContactTab/Ended';
import { useAppContext } from '../../../../AppContext';
import Connected from './ContactTab/Connected';
import postEvent from '../../../../utils/postEvent';
import getCurrentTime from '../../../../utils/timeFactory';

const ChatSidePanel = (): JSX.Element => {
  const { contacts, connectedContacts, selectedChat, setSelectedChat } = useAppContext();
  const prevContactId = useRef<string | null>(null);

  useEffect(() => {
    const endedContacts = contacts.filter(contact => !contact.isConnected());

    if (endedContacts.length > 0) {
      setSelectedChat(endedContacts[0].contactId);
    } else if (contacts.length > 0) {
      setSelectedChat(contacts[contacts.length - 1].contactId);
    } else {
      setSelectedChat(null);
    }
  }, [contacts, setSelectedChat]);

  useEffect(() => {
    const currentTime = getCurrentTime();

    if (prevContactId.current) {
      const previousTimestamp = localStorage.getItem(`contactFocusTimestamp`);

      if (previousTimestamp) {
        void postEvent(prevContactId.current, 'focus', {
          startedAt: new Date(JSON.parse(previousTimestamp) as Date),
          endedAt: currentTime
        });
      }
    }

    localStorage.setItem(`contactFocusTimestamp`, JSON.stringify(currentTime));

    prevContactId.current = selectedChat;
  }, [selectedChat]);

  const handleSetActive = (contactId: string): void => {
    setSelectedChat(contactId);
  };

  return (
    <Box sx={{ minWidth: '275px', padding: '8px', borderRight: '#DDDDDD 1px solid' }}>
      {contacts?.map(contact => (
        <Box key={contact?.contactId}>
          {!contact?.isSoftphoneCall() && contact?.getState().type === 'connecting' && <Incoming contact={contact} />}
          {connectedContacts.includes(contact) && (
            <Connected
              isAfterCallWork={!contact?.isConnected() && connectedContacts.includes(contact)}
              contactId={contact?.contactId}
              selectedContact={selectedChat}
              setSelectedContact={handleSetActive}
              initialDuration={contact?.getStateDuration()}
            />
          )}
          {!contact?.isSoftphoneCall() &&
            contact?.getState().type !== 'connecting' &&
            !connectedContacts.includes(contact) && <Ended contact={contact} />}
        </Box>
      ))}
    </Box>
  );
};

export default ChatSidePanel;

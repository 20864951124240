import React, { useEffect, useState } from 'react';
import Numpad from './Numpad';
import ContactPhoneInput from './ContactPhoneInput';
import { Box, IconButton } from '@coolblue-development/becky';
import TelephoneIcon from '../../../Icons/TelephoneIcon';
import ContactQueue from './ContactQueue';
import BackspaceIcon from '../../../Icons/BackspaceIcon';
import CallActionButton from './CallActionButton';
import { Country, countryCodes } from '../../../../../models/Country';
import { countrySelectorUtil } from '../../../../../utils/countrySelectorUtil';
import { datadogLogs } from '@datadog/browser-logs';
import parsePhoneNumber from 'libphonenumber-js';

const OutboundCallContainer = (): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState<string>('');

  useEffect(() => {
    const defaultCountry = countrySelectorUtil(countryCodes);
    setSelectedCountry(defaultCountry);
  }, []);

  const handleButtonClick = (value: string): void => {
    setInputValue(prevValue => prevValue + value);
  };

  const handleInputChange = (value: string): void => {
    setInputValue(value);
  };

  const handleBackspace = (): void => {
    setInputValue(prevValue => prevValue.slice(0, -1));
  };

  const handleCountrySelect = (country: Country): void => {
    setSelectedCountry(country);
  };

  const handleFormattedPhoneNumberChange = (formattedNumber: string): void => {
    setFormattedPhoneNumber(formattedNumber);
  };

  const callOutboundContact = (): void => {
    connect.agent(agent => {
      const endpoint = connect.Endpoint.byPhoneNumber(formattedPhoneNumber);

      agent.connect(endpoint, {
        success: function () {
          datadogLogs.logger.info(`Outbound call initiated to:`, {
            country: parsePhoneNumber(formattedPhoneNumber)?.country
          });
        },
        failure: function () {}
      });
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
      data-testid="outbound-container"
    >
      <ContactQueue />
      <ContactPhoneInput
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onCountrySelect={handleCountrySelect}
        selectedCountry={selectedCountry}
        onFormattedPhoneNumberChange={handleFormattedPhoneNumberChange}
      />
      <Numpad onButtonClick={handleButtonClick} />
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '48px', marginTop: 'auto' }}>
        <Box></Box>
        <CallActionButton
          disabled={!formattedPhoneNumber}
          icon={<TelephoneIcon />}
          label=""
          dataTestId="call-button"
          onClick={callOutboundContact}
          backgroundColor="#00B900"
          hoverColor="#008500"
          disabledBackgroundColor={'#868A90'}
          svgFill={!formattedPhoneNumber ? '#13171E' : ''}
        />
        <IconButton
          data-testid="backspace-button"
          sx={{
            boxShadow: 'none',
            background: 'none',
            svg: { width: 'auto', height: 'auto' },
            '&:hover': { background: 'none', boxShadow: 'none' }
          }}
          onClick={() => {
            handleBackspace();
          }}
        >
          <BackspaceIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default OutboundCallContainer;

import React, { useEffect, useState } from 'react';
import { Box, Typography, Label } from '@coolblue-development/becky';
import Timer from './Timer';
import LastChatTimer from './LastChatTimer';
import { ConnectedProps } from '../../../../../models/ConnectedProps';

const Connected: React.FC<ConnectedProps> = (props: ConnectedProps) => {
  const [isActive, setIsActive] = useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);
  const handleClick = (): void => props.setSelectedContact(props.contactId);
  const getBorderStyle = (): string => (isActive ? (props.isAfterCallWork ? '#FF6600' : '#0090E3') : '#DDDDDD');

  const unreadCount = parseInt(localStorage.getItem(`unreadMessageCount_${props.contactId}`) || '0');

  useEffect(() => {
    setIsActive(props.selectedContact === props.contactId);
  }, [props.contactId, props.selectedContact]);

  useEffect(() => {
    if (isActive) {
      localStorage.removeItem(`unreadMessageCount_${props.contactId}`);
      setUnreadMessageCount(0);
    } else {
      setUnreadMessageCount(unreadCount);
    }
  }, [unreadCount, props.selectedContact, isActive, props.contactId]);

  return (
    <>
      <Box
        data-testid={'connected'}
        sx={{
          padding: '15px',
          borderRadius: '5px',
          marginBottom: '8px',
          border: `1px solid ${getBorderStyle()}`
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <Typography variant="h5"> Customer </Typography>
          {props.isAfterCallWork && <Label color="orange">After call work</Label>}
          {unreadMessageCount !== null && unreadMessageCount > 0 && (
            <Label color="orange" data-testid="unreadMessageCount">
              {unreadMessageCount}
            </Label>
          )}
        </Box>
        <Timer isAfterCallWork={props.isAfterCallWork} initialDuration={props.initialDuration} />
        {!props.isAfterCallWork && <LastChatTimer contactId={props.contactId} />}
      </Box>
    </>
  );
};

export default Connected;

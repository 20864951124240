import React, { useEffect, useState } from 'react';
import { Box } from '@coolblue-development/becky';
import ContactsIcon from '../../../Icons/ContactsIcon';
import HoldIcon from '../../../Icons/HoldIcon';
import MuteIcon from '../../../Icons/MuteIcon';
import TelephoneIcon from '../../../Icons/TelephoneIcon';
import { defaultCallActionStateValue, useAppContext } from '../../../../../AppContext';
import CallActionButton from './CallActionButton';
import { TelephonyWidgetContainer } from '../../../../../models/TelephonyWidgetContainer';
import TransferIcon from '../../../Icons/TransferIcon';
import { CAPTAIN } from '../../../../../utils/constants';
import { IsB2bAgent } from '../../../../../utils/isB2bAgent';
import MergeIcon from '../../../Icons/MergeIcon';
import { getVoiceContact } from '../../../../../utils/getVoiceContact';
import NumpadIcon from '../../../Icons/NumpadIcon';

const CallActionContainer = (): JSX.Element => {
  const {
    agentVoiceConnection,
    setActiveTelephonyWidgetContainer,
    thirdPartyVoiceConnection,
    voiceContact,
    setIsAgentMute,
    isAgentMute,
    isAgentHold,
    setIsAgentHold
  } = useAppContext();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [isInbound, setIsInbound] = useState<boolean>(false);
  const [isOutbound, setIsOutbound] = useState<boolean>(false);
  const [isEnded, setIsEnded] = useState<boolean>(false);

  const isHoldActive = (): boolean => (isAgentHold.customer && !thirdPartyVoiceConnection) || isAgentHold.thirdParty;
  const isMuteDisabled = (): boolean => isHoldActive() || !isConnected;
  const getHoldBackgroundColor = (state: boolean): string => (state ? '#FFAE00' : 'none');
  const getMuteBackgroundColor = (state: boolean): string => (state || !isConnected ? '#EC4040' : 'none');
  const getSvgFillColor = (state: boolean): string => (state ? '#868A90' : '');

  useEffect(() => {
    const contact = getVoiceContact(voiceContact?.getContactId());
    const voiceContactState = contact?.getState().type;

    setIsConnected(contact?.isConnected() ?? false);
    setIsEnded(voiceContactState === connect.ContactStateType.ENDED);
    setIsInbound(contact?.isInbound() ?? false);
    setIsConnecting(
      voiceContactState === connect.ContactStateType.CONNECTING ||
        voiceContactState === connect.ContactStateType.INCOMING
    );
    setIsOutbound(
      (voiceContactState === connect.ContactStateType.CONNECTING ||
        voiceContactState === connect.ContactStateType.INCOMING) &&
        !contact?.isInbound()
    );

    if (!contact) {
      setIsAgentHold(defaultCallActionStateValue);
      setIsAgentMute(false);
    }
  }, [setIsAgentHold, setIsAgentMute, voiceContact]);

  useEffect(() => {
    if (thirdPartyVoiceConnection?.isOnHold()) {
      setIsAgentHold(prevState => ({ ...prevState, thirdParty: true }));
    }

    if (agentVoiceConnection?.isOnHold()) {
      setIsAgentHold(prevState => ({ ...prevState, customer: true }));
    }
  }, [agentVoiceConnection, setIsAgentHold, thirdPartyVoiceConnection]);

  const handleMuteClick = (): void => {
    connect.agent(agent => {
      if (isAgentMute) {
        setIsAgentMute(false);
        agent.unmute();
      } else {
        setIsAgentMute(true);
        agent.mute();
      }
    });
  };

  const handleHoldClick = (): void => {
    if (thirdPartyVoiceConnection) {
      if (thirdPartyVoiceConnection.isOnHold()) {
        setIsAgentHold(prevState => ({ ...prevState, thirdParty: false }));
        thirdPartyVoiceConnection.resume();
      } else if (thirdPartyVoiceConnection.isConnected()) {
        setIsAgentHold(prevState => ({ ...prevState, thirdParty: true }));
        thirdPartyVoiceConnection.hold();
      }
    }

    if (agentVoiceConnection && !thirdPartyVoiceConnection) {
      if (agentVoiceConnection.isOnHold()) {
        setIsAgentHold(prevState => ({ ...prevState, customer: false }));
        agentVoiceConnection.resume();
      } else {
        setIsAgentHold(prevState => ({ ...prevState, customer: true }));
        agentVoiceConnection.hold();
      }
    }
  };

  const handleEndClick = (): void => {
    connect.agent(agent => {
      if (agent.getRoutingProfile().name.toLowerCase().includes(CAPTAIN)) {
        voiceContact?.getAgentConnection().destroy();
        return;
      }

      if (thirdPartyVoiceConnection) {
        thirdPartyVoiceConnection.destroy();
      } else {
        agentVoiceConnection?.destroy();
      }
    });
  };

  const handleMergeClick = (): void => {
    setIsAgentHold({ thirdParty: false, customer: false });
    thirdPartyVoiceConnection?.resume();
    agentVoiceConnection?.resume();
  };

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
          marginBottom: '48px'
        }}
      >
        {IsB2bAgent() && thirdPartyVoiceConnection && agentVoiceConnection?.isOnHold() ? (
          <CallActionButton
            icon={<MergeIcon />}
            label="Merge"
            dataTestId="merge-button"
            disabled={!isConnected}
            onClick={handleMergeClick}
            backgroundColor="none"
            hoverColor="#33373F"
            svgFill={getSvgFillColor(!isConnected)}
          />
        ) : (
          <CallActionButton
            icon={<ContactsIcon />}
            label="Contacts"
            dataTestId="quickConnects-button"
            disabled={!isConnected}
            onClick={() => setActiveTelephonyWidgetContainer(TelephonyWidgetContainer.QUICK_CONNECT)}
            backgroundColor="none"
            hoverColor="#33373F"
            svgFill={getSvgFillColor(!isConnected)}
          />
        )}

        <CallActionButton
          icon={<HoldIcon />}
          label="Hold"
          dataTestId="hold-button"
          disabled={!isConnected}
          onClick={handleHoldClick}
          backgroundColor={getHoldBackgroundColor(isHoldActive())}
          hoverColor="#89620F"
          svgFill={getSvgFillColor(!isConnected)}
        />

        <CallActionButton
          icon={<MuteIcon />}
          label="Mute"
          dataTestId="mute-button"
          disabled={isMuteDisabled()}
          onClick={handleMuteClick}
          backgroundColor={getMuteBackgroundColor(isAgentMute)}
          hoverColor="#680B0F"
          svgFill={getSvgFillColor(isMuteDisabled())}
        />
      </Box>

      {isInbound && !isConnected && !isEnded ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
          <CallActionButton
            icon={<TelephoneIcon rotation={135} />}
            label="Reject"
            dataTestId="reject-button"
            disabled={!isConnecting}
            onClick={() => voiceContact?.reject()}
            backgroundColor="#E50000"
            hoverColor="#680B0F"
            disabledBackgroundColor={'#868A90'}
            svgFill={!isConnecting ? '#13171E' : ''}
          />

          {voiceContact?.getType() !== connect.ContactType.QUEUE_CALLBACK ||
          voiceContact?.getState().type !== connect.ContactStateType.CONNECTING ? (
            <CallActionButton
              icon={<TelephoneIcon />}
              label="Accept"
              dataTestId="accept-button"
              disabled={!isConnecting || isAccepted}
              onClick={() =>
                voiceContact?.accept({
                  success: function () {
                    setIsAccepted(true);
                  },
                  failure: function () {
                    throw new Error();
                  }
                })
              }
              backgroundColor="#00B900"
              hoverColor="#008500"
              disabledBackgroundColor={'#868A90'}
              svgFill={!isConnecting || isAccepted ? '#13171E' : ''}
            />
          ) : null}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'
          }}
        >
          <CallActionButton
            icon={<NumpadIcon />}
            label="Numpad"
            dataTestId="numpad-button"
            disabled={!isConnected}
            onClick={() => setActiveTelephonyWidgetContainer(TelephonyWidgetContainer.NUMPAD)}
            backgroundColor="none"
            hoverColor="#33373F"
            svgFill={getSvgFillColor(!isConnected)}
          />
          <CallActionButton
            icon={<TelephoneIcon rotation={135} />}
            label="End"
            dataTestId="end-button"
            disabled={!isConnected && !isOutbound}
            onClick={handleEndClick}
            backgroundColor="#E50000"
            hoverColor="#680B0F"
            disabledBackgroundColor={'#868A90'}
            svgFill={!isConnected && !isOutbound ? '#13171E' : ''}
          />
          {thirdPartyVoiceConnection ? (
            <CallActionButton
              icon={<TransferIcon />}
              label="Transfer"
              dataTestId="transfer-button"
              disabled={!isConnected && !isOutbound}
              onClick={() => {
                voiceContact?.getAgentConnection().destroy({
                  success: function () {
                    setIsEnded(true);
                  },
                  failure: function (error) {
                    throw new Error('Unable to transfer call:' + error);
                  }
                });
              }}
              backgroundColor="#00B900"
              hoverColor="#008500"
              disabledBackgroundColor="#868A90"
              svgFill={isEnded ? '#13171E' : ''}
            />
          ) : null}
        </Box>
      )}
    </>
  );
};

export default CallActionContainer;

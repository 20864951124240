import ChatSidePanel from './ChatSidePanel';
import { Box } from '@coolblue-development/becky';
import ChatTopPanel from './ChatTopPanel';
import ChatBody from './ChatBody';
import ChatInput from './InputForm/ChatInput';
import { useAppContext } from '../../../../AppContext';

const Chat = (): JSX.Element => {
  const { telephonyStatusBarOpen } = useAppContext();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        paddingBottom: telephonyStatusBarOpen ? '170px' : '120px'
      }}
    >
      <ChatSidePanel />
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <ChatTopPanel />
        <ChatBody />
        <ChatInput />
      </Box>
    </Box>
  );
};

export default Chat;

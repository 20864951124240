import { FormControl, MenuItem } from '@coolblue-development/becky';
import { Select } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FormLabel, SelectChangeEvent } from '@mui/material';
import { AudioDevice } from './Settings';

interface SelectOptionProps {
  name: string;
  devices: AudioDevice[];
  label: string;
}

const SelectOption: React.FC<SelectOptionProps> = ({ name, devices, label }) => {
  const [selectedDevice, setSelectedDevice] = useState<string>('default');

  const handleDeviceChange = (event: SelectChangeEvent<string>): void => {
    const selectedDeviceId = event.target.value;
    setSelectedDevice(selectedDeviceId);

    connect.agent(agent => {
      switch (name) {
        case 'Microphone':
          agent.setMicrophoneDevice(selectedDeviceId);
          break;
        case 'Ringer':
          agent.setRingerDevice(selectedDeviceId);
          break;
        case 'Speaker':
          agent.setSpeakerDevice(selectedDeviceId);
          break;
        default:
          break;
      }
    });

    localStorage.setItem(name, selectedDeviceId);
  };

  useEffect(() => {
    const savedDeviceId = localStorage.getItem(name);
    if (savedDeviceId) {
      setSelectedDevice(savedDeviceId);
    }
  }, [name]);

  return (
    <FormControl sx={{ marginBottom: '10px' }} fullWidth>
      <FormLabel id={label}>{label}</FormLabel>
      <Select
        value={selectedDevice}
        onChange={handleDeviceChange}
        aria-labelledby={label}
        inputProps={{ 'data-testid': 'device-select' }}
      >
        {devices.map(device => (
          <MenuItem key={device.deviceId} value={device.deviceId}>
            {device.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectOption;

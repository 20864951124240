import React from 'react';
import ContactQueue from './ContactQueue';
import ContactContainer from './ContactContainer';
import CallActionContainer from './CallActionContainer';
import { Box } from '@coolblue-development/becky';

const CallContainer = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
      }}
      data-testid="call-container"
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '48px' }}>
        <ContactQueue />
        <ContactContainer />
      </Box>
      <Box>
        <CallActionContainer />
      </Box>
    </Box>
  );
};

export default CallContainer;

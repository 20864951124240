import * as React from 'react';
import * as SignalR from '@microsoft/signalr';
import { datadogLogs } from '@datadog/browser-logs';
import useAppBrowserLock from '../hooks/useAppBrowserLock';

type UseSignalROptions = {
  connectionName: string;
  url: string;
  contextConnection?: SignalR.HubConnection | undefined; // This parameter is used to make the connection unique for every hook usage
  autoConnect?: boolean;
  maxConnectAttempts?: number;
};

type UseSignalRReturn = {
  connection: SignalR.HubConnection | undefined;
  connectionState: SignalR.HubConnectionState;

  start: () => Promise<void>;
  stop: SignalR.HubConnection['stop'] | undefined;

  on: SignalR.HubConnection['on'] | undefined;
  off: SignalR.HubConnection['off'] | undefined;
  invoke: SignalR.HubConnection['invoke'] | undefined;
};

export const START_WS_RETRY_DELAY = 5000;

const testWindow = window as Window &
  typeof globalThis & {
    signalRMock?: SignalR.HubConnection;
    wsReady?: boolean;
  };

// This hook is used to create a SignalR connection and manage its lifecycle
// It provides auto / manual connect feature with retry mechanism
// It can be used with a context to keep a unique connection between multiple hook calls
const useSignalR = ({
  url,
  contextConnection,
  connectionName,
  autoConnect = true,
  maxConnectAttempts = 0 // 0 means infinite
}: UseSignalROptions): UseSignalRReturn => {
  if (!url) throw new Error('URL is required');

  // This hook prevents the websocket from being disconnected when the tab is not active
  useAppBrowserLock(`${connectionName.toLowerCase()}_websocket`, true);

  const [connection, setConnection] = React.useState<SignalR.HubConnection | undefined>(contextConnection || undefined);
  React.useEffect(() => {
    if (connection) return;

    const newConnection =
      testWindow.signalRMock ||
      new SignalR.HubConnectionBuilder()
        .withUrl(url, {
          skipNegotiation: true,
          transport: SignalR.HttpTransportType.WebSockets
        })
        .withAutomaticReconnect()
        .configureLogging(SignalR.LogLevel.Error)
        .build();

    setConnection(newConnection);
  }, [connection, url]);

  const [hasAutoStarted, setHasAutoStarted] = React.useState<boolean>(false);
  const [state, setState] = React.useState<SignalR.HubConnectionState>(
    contextConnection?.state || SignalR.HubConnectionState.Disconnected
  );

  // Register and cleanup connection lifecycle events
  React.useEffect(() => {
    const onclose = (error: Error | undefined): void => {
      setState(SignalR.HubConnectionState.Disconnected);
      if (error)
        datadogLogs.logger.warn(`Websocket connection closed`, { integration: 'cassie', standalone: true }, error);
    };
    const onreconnecting = (error: Error | undefined): void => {
      setState(connection?.state || SignalR.HubConnectionState.Reconnecting);
      if (error)
        datadogLogs.logger.warn(
          `Websocket connection stopped, reconnecting...`,
          { integration: 'cassie', standalone: true },
          error
        );
    };
    const onreconnected = (): void => {
      setState(connection?.state || SignalR.HubConnectionState.Connected);
      datadogLogs.logger.warn(`Websocket connection reconnected`, { integration: 'cassie', standalone: true });
    };

    if (connection) {
      connection.onclose(onclose);
      connection.onreconnecting(onreconnecting);
      connection.onreconnected(onreconnected);
    }
  }, [connection]);

  // Start connection handler with auto-retry
  const startConnection = React.useCallback(
    async (attempt = 1): Promise<void> => {
      if (!connection) return Promise.reject(new Error("Can't start connection, connection is not defined"));
      if (connection.state !== SignalR.HubConnectionState.Disconnected)
        return Promise.reject(
          new Error(`Can't start connection, the connection state is not disconnected: ${connection.state}`)
        );

      return connection
        .start()
        .then(() => {
          setState(SignalR.HubConnectionState.Connected);
        })
        .catch((err: Error) => {
          datadogLogs.logger.error(
            `Unable to establish a WS connection with ${url} (attempt #${attempt}).`,
            { integration: 'cassie', standalone: true },
            err
          );
          setState(SignalR.HubConnectionState.Disconnected);

          if (maxConnectAttempts === 0 || attempt < maxConnectAttempts) {
            setTimeout(async () => startConnection(attempt + 1), START_WS_RETRY_DELAY);
          }
        });
    },
    [connection, maxConnectAttempts, url]
  );

  // Auto-start connection when autoConnect is enabled
  React.useEffect(() => {
    if (connection && autoConnect && !hasAutoStarted && state === SignalR.HubConnectionState.Disconnected) {
      setHasAutoStarted(true);
      void startConnection();
    }
  }, [autoConnect, connection, hasAutoStarted, startConnection, state]);

  return {
    connection,
    connectionState: state,

    start: startConnection,
    stop: connection?.stop.bind(connection),

    on: connection?.on.bind(connection),
    off: connection?.off.bind(connection),
    invoke: connection?.invoke.bind(connection)
  } as const;
};

export default useSignalR;

export interface FeatureFlagInterface {
  name: string;
  label: string;
  enabled: boolean;
}

export enum FeatureFlag {}

export const featureFlagList: FeatureFlagInterface[] = [];

// This function syncs the user's feature flags with the up-to-date hardcoded list (previously enabled flags are kept enabled)
const syncFeatureFlags = (userFeatureFlags: FeatureFlagInterface[]): FeatureFlagInterface[] => {
  // Get the names of the feature flags that the user has enabled
  const userEnabledFeatureFlags = userFeatureFlags.filter(flag => flag.enabled).map(flag => flag.name);

  // Return the updated list of feature flags with the user's enabled flags
  return featureFlagList.map(flag => ({
    ...flag,
    enabled: userEnabledFeatureFlags.includes(flag.name) || flag.enabled
  }));
};

export default syncFeatureFlags;

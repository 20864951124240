import { Avatar, Box, Container, Skeleton, Typography } from '@mui/material';

function AuthenticationExpired(): JSX.Element {
  return (
    <Container maxWidth="xl" sx={{ position: 'absolute', margin: '0', top: '50%', transform: 'translateY(-50%)' }}>
      <Box sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
        <Box sx={{ margin: 2 }}>
          <Skeleton variant="circular">
            <Avatar sx={{ width: 140, height: 140 }} />
          </Skeleton>
        </Box>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h1" component="div">
          Session expired
        </Typography>
        <Typography variant="subtitle1" component="div">
          Your session has expired. Please refresh the page
        </Typography>
      </Box>
    </Container>
  );
}

export default AuthenticationExpired;

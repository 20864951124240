import { OutlinedInput, Button, Box, IconButton } from '@coolblue-development/becky';
import React, { useRef, useState } from 'react';
import { useAppContext } from '../../../../../AppContext';
import { getLocalStorageBoolean } from '../../../../../utils/localStorageUtil';
import ImagePreview from './ImagePreview';
import ImageButton from './ImageButton';
import ValidationMessage from './ValidationMessage';
import { TYPING_EVENT_DURATION } from '../../../../../utils/constants';
import QuickRepliesContainer from './QuickRepliesContainer';

const ChatInputForm = (): JSX.Element => {
  const { agentMessage, setAgentMessage, selectedChat, agentChatSession } = useAppContext();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);
  const isImageSharingEnabled = getLocalStorageBoolean('isImageSharingEnabled');
  const chatSession = agentChatSession.find(session => session.getChatDetails().contactId === selectedChat);
  const isTypingRef = useRef(false);

  const handleInputTyping = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAgentMessage(prevAgentMessages => {
      const filteredMessages = prevAgentMessages.filter(msg => msg.selectedChat !== selectedChat);
      return [...filteredMessages, { selectedChat, message: e.target.value }];
    });

    if (!isTypingRef.current) {
      void chatSession?.sendEvent({
        contentType: 'application/vnd.amazonaws.connect.event.typing'
      });

      isTypingRef.current = true;

      setTimeout(() => {
        isTypingRef.current = false;
      }, TYPING_EVENT_DURATION);
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent): Promise<void> => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      void sendMessage();
    }
  };

  const sendMessage = async (): Promise<void> => {
    if (selectedChat && chatSession) {
      const { message } = agentMessage.find(msg => msg.selectedChat === selectedChat) || { message: '' };

      if (message) {
        void chatSession.sendMessage({
          contentType: 'text/markdown',
          message: message
        });
        setAgentMessage(prevAgentMessages => prevAgentMessages.filter(msg => msg.selectedChat !== selectedChat));
      }

      if (selectedImage) {
        setLoader(true);
        await chatSession.sendAttachment({ attachment: selectedImage });
        setLoader(false);
        setSelectedImage(null);
      }
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];

    if (file) {
      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB

      if (file.size > maxSizeInBytes) {
        setHasValidationError(true);
      } else {
        setHasValidationError(false);
        setSelectedImage(file);
      }
    }
  };

  const handleRemoveImage = (): void => {
    setSelectedImage(null);
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <OutlinedInput
          sx={{
            borderTop: 'none',
            width: '100%',
            borderRadius: '0px 0px 4px 4px',
            paddingBottom: '55px !important',
            paddingLeft: selectedImage ? '105px !important' : ''
          }}
          inputProps={{ 'data-testid': 'input-field' }}
          placeholder="Start typing a message"
          multiline
          minRows={4}
          maxRows={4}
          value={agentMessage.find(msg => msg.selectedChat === selectedChat)?.message || ''}
          onChange={handleInputTyping}
          onKeyDown={handleKeyDown}
        />
        {selectedImage && (
          <ImagePreview selectedImage={selectedImage} onRemoveImage={handleRemoveImage} loader={loader} />
        )}
        <QuickRepliesContainer />
        <Box sx={{ position: 'absolute', bottom: 10, right: 10, display: 'flex', gap: '8px' }}>
          {isImageSharingEnabled ? (
            <IconButton
              component="label"
              preset="text"
              iconSize="small"
              sx={{
                width: 'auto',
                height: 'auto',
                padding: '8px',
                margin: '8px 0px',
                ':hover': { background: '#F3F3F3' }
              }}
            >
              <input
                data-testid="select-image"
                type="file"
                accept=".jpg,.jpeg,.heic,.png"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
              <ImageButton />
            </IconButton>
          ) : null}
          <Button type="submit" size="medium" onClick={sendMessage} data-testid="send-button">
            Send
          </Button>
        </Box>
      </Box>
      {hasValidationError && <ValidationMessage onClose={() => setHasValidationError(false)} />}
    </>
  );
};

export default ChatInputForm;

import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Box, Typography } from '@coolblue-development/becky';
import { useAppContext } from '../../../../AppContext';

const DigitalAssistentSummary = (): JSX.Element | null => {
  const { contacts, selectedChat } = useAppContext();
  const [summary, setSummary] = useState<string>('');

  useEffect(() => {
    const selectedConnectedChat = contacts.filter(
      contact => contact.isConnected() && contact.contactId === selectedChat
    );
    const chatbotSummary = selectedConnectedChat[0]?.getAttributes().chatbot_summary?.value;

    setSummary(chatbotSummary ?? '');
  }, [contacts, selectedChat]);

  return summary ? (
    <>
      <Box width="100%" bgcolor={'#fff'} borderRadius={'5px'} padding={'15px'} data-testid="summary">
        <Typography variant="h2">Summary Digital Assistent</Typography>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{summary}</ReactMarkdown>
      </Box>
    </>
  ) : null;
};

export default DigitalAssistentSummary;

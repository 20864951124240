import CassieContainer from './CassieContainer';
import { CassieWebsocketContextProvider } from './websocket/CassieWebsocketContext';
import Contact from '../../models/Contact';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../AppContext';
import { getVoiceContact } from '../../utils/getVoiceContact';

interface CassieInjectorProps {
  contact: Contact | undefined;
}

const CassieInjector: React.FC<CassieInjectorProps> = ({ contact }) => {
  const { voiceContact } = useAppContext();
  const [routingGroup, setRoutingGroup] = useState<string>('default'); // Default value can not be an empty string

  useEffect(() => {
    const contact = getVoiceContact(voiceContact?.getContactId());

    if (!contact?.getQueue()) {
      return;
    }

    setRoutingGroup(contact?.getQueue().name);
  }, [voiceContact]);

  return (
    <CassieWebsocketContextProvider>
      <CassieContainer contact={contact} routingGroup={routingGroup} />
    </CassieWebsocketContextProvider>
  );
};

export default CassieInjector;

import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { ArrowDown } from '@coolblue-development/icons';
import MuiAccordion, { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@coolblue-development/becky';

const AccordionMUI = styled((props: MuiAccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummaryMUI = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowDown />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  gap: theme.spacing(1),
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetailsMUI = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

type AccordionProps = {
  accordion?: Omit<MuiAccordionProps, 'children'>;
  summary?: AccordionSummaryProps;
  details?: AccordionDetailsProps;
  title: string | ReactNode;
  children: ReactNode;
};

const Accordion = ({ title, children, ...rest }: AccordionProps): JSX.Element => {
  return (
    <div>
      <AccordionMUI {...rest.accordion}>
        <AccordionSummaryMUI aria-controls="panel1d-content" id="panel1d-header" {...rest.summary}>
          <Typography fontWeight="bold">{title}</Typography>
        </AccordionSummaryMUI>
        <AccordionDetailsMUI {...rest.details}>
          <Box display="flex" flexDirection="column" p={2} gap={4}>
            {children}
          </Box>
        </AccordionDetailsMUI>
      </AccordionMUI>
    </div>
  );
};

export default Accordion;

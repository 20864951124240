import { Box, Divider, OutlinedInput } from '@mui/material';
import { useAppContext } from '../../../../../AppContext';
import Numpad from './Numpad';
import { useState } from 'react';
import BackButton from './BackButton';

const NumpadContainer = (): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>('');
  const { thirdPartyVoiceConnection, agentVoiceConnection } = useAppContext();

  const handleSendDigits = (value: string): void => {
    if (thirdPartyVoiceConnection) {
      thirdPartyVoiceConnection.sendDigits(value);
      return;
    }

    if (agentVoiceConnection) {
      agentVoiceConnection.sendDigits(value);
      return;
    }
  };

  const handleButtonClick = (value: string): void => {
    setInputValue(prevValue => prevValue + value);
    handleSendDigits(value);
  };

  return (
    <>
      <Box sx={{ display: 'flex', marginBottom: '10px', alignItems: 'center', gap: '10px' }}>
        <BackButton />
      </Box>
      <Divider sx={{ background: '#394046' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', gap: '20px' }}>
        <Box
          sx={{
            '& .MuiOutlinedInput-root': {
              border: '1px solid #262930',
              '&.Mui-focused fieldset': {
                border: 'none'
              }
            }
          }}
        >
          <OutlinedInput
            sx={{ width: '100%' }}
            value={inputValue}
            inputProps={{ style: { color: 'white' } }}
            placeholder="123 45 67 89"
          />
        </Box>
        <Numpad onButtonClick={handleButtonClick} />
      </Box>
    </>
  );
};

export default NumpadContainer;

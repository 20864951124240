import { Grid, Typography } from '@coolblue-development/becky';
import React, { useEffect, useState } from 'react';
import TimerIcon from '../Icons/TimerIcon';
import { useAppContext } from '../../../AppContext';
import { formatDuration } from '../../../utils/formatDuration';
import { getVoiceContact } from '../../../utils/getVoiceContact';

const TelephonyStatusBar = (): JSX.Element => {
  const { agentVoiceConnection, voiceContact, agentState } = useAppContext();
  const [voiceContactState, setVoiceContactState] = useState<connect.ContactStateType>();
  const [duration, setDuration] = useState<number>(0);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [backgroundColor, setBackgroundColor] = useState<string>('');

  useEffect(() => {
    const contact = getVoiceContact(voiceContact?.getContactId());

    setVoiceContactState(contact?.getState().type);
    setPhoneNumber(contact?.getInitialConnection().getEndpoint().phoneNumber ?? '');
  }, [voiceContact]);

  useEffect(() => {
    const contact = getVoiceContact(voiceContact?.getContactId());
    setDuration(contact?.getStateDuration() ?? 0);

    switch (voiceContactState) {
      case connect.ContactStateType.INCOMING:
        setStatus('Callback incoming');
        setBackgroundColor('#00B900');
        break;
      case connect.ContactStateType.CONNECTING:
        setStatus('Incoming call');
        setBackgroundColor('#00B900');
        break;
      case connect.ContactStateType.CONNECTED:
        setStatus('Connected call');
        setBackgroundColor('#008500');
        if (agentVoiceConnection?.isOnHold()) {
          setDuration(agentVoiceConnection?.getStateDuration());
          setStatus('On hold');
          setBackgroundColor('#FFAE00');
        }
        break;
      case connect.ContactStateType.MISSED:
        setStatus('Missed call');
        setBackgroundColor('#E50000');
        break;
      case connect.ContactStateType.ENDED:
        setStatus('After call work');
        setBackgroundColor('#FF6600');
        break;
      case connect.ContactStateType.ERROR:
        setStatus('Ended call');
        setBackgroundColor('#E50000');
        break;
    }

    const timerId = setInterval(() => setDuration(prev => prev + 1000), 1000);
    return () => clearInterval(timerId);
  }, [agentVoiceConnection, voiceContact, voiceContactState]);

  useEffect(() => {
    if (!voiceContact || !agentVoiceConnection) {
      connect.agent(agent => {
        setBackgroundColor('#FFAE00');
        setTimeout(() => {
          setDuration(agent.getStateDuration());
        }, 1000); // 1 second delay before setting duration
      });
    }
  }, [agentState, agentVoiceConnection, voiceContact]);

  return (
    <Grid container sx={{ background: backgroundColor, color: '#fff', padding: '15px' }}>
      <Grid item xs={4} gap={2} sx={{ display: 'flex', alignItems: 'center', svg: { height: 'auto', width: '20px' } }}>
        <TimerIcon color="white" />
        <Typography>{formatDuration(duration)}</Typography>
      </Grid>
      <Grid item xs={8} gap={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        {voiceContact && (
          <>
            <Typography variant="h5">{phoneNumber}</Typography> | <Typography>{status}</Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default TelephonyStatusBar;

import { Box, Typography } from '@mui/material';
import CatoLogo from './CatoContainer/Header/CatoLogo';

function Error(): JSX.Element {
  return (
    <Box sx={{ position: 'absolute', width: '100%', top: '50%', transform: 'translateY(-50%)', textAlign: 'center' }}>
      <Box>
        <CatoLogo />
      </Box>
      <Box>
        <Typography variant="h1" component="div">
          An error occured
        </Typography>
        <Typography variant="subtitle1" component="div">
          Please refresh the page
        </Typography>
      </Box>
    </Box>
  );
}

export default Error;

import { Typography, styled } from '@mui/material';

import { Button } from '@coolblue-development/becky';
import { CASSIE_PWA_PROTOCOL_URL } from './constants';
import SidepanelIcon from '../CatoContainer/Icons/SidepanelIcon';

const CassieShowButton: React.FC = () => {
  const handleOnShowCassieClick = (): void => {
    window.open(CASSIE_PWA_PROTOCOL_URL, '_blank');
  };

  return (
    <ShowButton
      preset="text"
      onClick={handleOnShowCassieClick}
      sx={{ color: '#111111', hoverColor: '#F2F7FC', display: 'flex', gap: '8px', padding: '8px', zIndex: 1 }}
    >
      <Typography sx={{ fontSize: '1.2rem' }}>Show Cassie</Typography>
      <SidepanelIcon height={'20px'} width={'20px'} />
    </ShowButton>
  );
};

const ShowButton = styled(Button)`
  & svg {
    fill: initial !important;
    width: auto !important;
    height: auto !important;
  }
`;

export default CassieShowButton;

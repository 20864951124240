import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@coolblue-development/becky';
import InboundIcon from '../../../Icons/InboundIcon';
import { useAppContext } from '../../../../../AppContext';
import { getVoiceContact } from '../../../../../utils/getVoiceContact';

const ContactQueue = (): JSX.Element => {
  const { contacts, voiceContact } = useAppContext();
  const [contactQueue, setContactQueue] = useState<string>('');
  const [isInbound, setIsInbound] = useState<boolean>(false);

  useEffect(() => {
    const contact = getVoiceContact(voiceContact?.getContactId());

    setIsInbound(contact?.isInbound() ?? false);
    setContactQueue(contact?.getQueue().name ?? '');
  }, [contacts, voiceContact]);

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <InboundIcon color={isInbound ? '#00B900' : '#FF6600'} rotation={isInbound ? 0 : 180} />
        <Typography color={'#fff'} variant="body1" marginLeft={1}>
          {isInbound ? 'Inbound call at:' : 'Outbound call'}
        </Typography>
      </Box>
      {isInbound && (
        <Typography color={'white'} variant="h4">
          {contactQueue}
        </Typography>
      )}
    </Box>
  );
};

export default ContactQueue;

import React from 'react';
import { Fab, Box } from '@coolblue-development/becky';
import TelephoneIcon from '../Icons/TelephoneIcon';
import MinimizeIcon from '../Icons/MinimizeIcon';
import { useAppContext } from '../../../AppContext';

const TelephonyWidgetButton = (): JSX.Element => {
  const { isTelephonyWidgetOpen, setIsTelephonyWidgetOpen } = useAppContext();

  const handleToggle = (): void => {
    setIsTelephonyWidgetOpen(prevIsTelephonyWidgetOpen => !prevIsTelephonyWidgetOpen);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '58px' }}>
        <Fab sx={{ background: '#00B900', ':hover': { background: '#008500' } }} onClick={handleToggle}>
          {isTelephonyWidgetOpen ? <MinimizeIcon /> : <TelephoneIcon />}
        </Fab>
      </Box>
    </>
  );
};

export default TelephonyWidgetButton;

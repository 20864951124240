import React from 'react';
import { Button, Typography } from '@coolblue-development/becky';
import { useAppContext } from '../../../../../AppContext';
import CloseChatBubbleIcon from '../../../Icons/CloseChatBubbleIcon';
import CheckmarkIcon from '../../../Icons/CheckMarkIcon';

const Close = (): JSX.Element => {
  const { contacts, selectedChat, connectedContacts } = useAppContext();
  const matchingContact = contacts.find(
    contact => contact.contactId === selectedChat && connectedContacts.includes(contact)
  );
  const endedChat = matchingContact && matchingContact.isConnected();

  const closeConversationStyle = {
    background: '#E50000',
    boxShadow: 'inset 0 -2px 0 #AA2222',
    ':hover': { background: '#BE0000', boxShadow: 'inset 0 -2px 0 #680B0F' }
  };

  const handleEndChat = (): void => {
    matchingContact?.getAgentConnection().destroy({
      success: function () {},
      failure: function () {}
    });
  };

  return (
    <>
      {connectedContacts.length > 0 && (
        <Button
          onClick={handleEndChat}
          size="small"
          preset="callToAction"
          sx={endedChat ? closeConversationStyle : null}
        >
          {endedChat ? <CloseChatBubbleIcon /> : <CheckmarkIcon />}
          <Typography variant="h5" sx={{ marginLeft: '5px' }}>
            {endedChat ? 'Close conversation' : 'Close contact'}
          </Typography>
        </Button>
      )}
    </>
  );
};

export default Close;

import { Box } from '@coolblue-development/becky';
import React, { useState, useEffect } from 'react';
import SelectOption from './SelectOption';
import { datadogLogs } from '@datadog/browser-logs';

export interface AudioDevice {
  deviceId: string;
  label: string;
  kind: 'audioinput' | 'audiooutput';
}

const Settings = (): JSX.Element => {
  const [audioInputDevices, setAudioInputDevices] = useState<AudioDevice[]>([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState<AudioDevice[]>([]);

  useEffect(() => {
    connect.core
      .getFrameMediaDevices(1000)
      .then((devices: AudioDevice[]) => {
        const inputDevices = devices.filter(device => device.kind === 'audioinput');
        const outputDevices = devices.filter(device => device.kind === 'audiooutput');
        setAudioInputDevices(inputDevices);
        setAudioOutputDevices(outputDevices);
      })
      .catch((err: Error) => {
        datadogLogs.logger.error(`Unable to get media devices`, { mediaDevices: true }, err);
      });
  }, []);

  return (
    <Box width={300}>
      <SelectOption name="Speaker" devices={audioOutputDevices} label="Speaker" />
      <SelectOption name="Microphone" devices={audioInputDevices} label="Microphone" />
      <SelectOption name="Ringer" devices={audioOutputDevices} label="Ringer" />
    </Box>
  );
};

export default Settings;

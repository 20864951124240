import './index.css';

import App from './App';
import { AppProvider } from './AppContext';
import React from 'react';
import ReactDOM from 'react-dom/client';
import config from './config';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AppProvider>
      <App
        ccpInstanceURL={config.ccpInstanceURL}
        cppSamURL={config.cppSamURL}
        title={config.title}
        region={config.region}
      />
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
